import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import {
    create as createLead,
} from 'Redux/modules/public/leads/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            createLead: dispatch(createLead),
        },
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-public-adept-welcome-form-section" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);
