import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import Head from 'Components/core/Head';
import FormSection from 'Components/public/adept/WelcomeFormSection';

export default class PublicAdeptWelcome extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        const { location } = this.props;

        return (
            <StyledComponent
                className="component-adept-welcome"
                styles={require('./styles')}
            >
                <Head
                    title="Dołącz"
                    description="Dołącz do społeczności FitAdept"
                />
                <FormSection location={location} />
            </StyledComponent>
        );
    }
}
